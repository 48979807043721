import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export default function PrivacyPolicy() {
  return (
    <>
      <Container maxWidth="md" sx={{mt: 5, mb: 15}}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Privacy Policy
            </Typography>
            <Typography paragraph>
              This Privacy Policy describes how Palnu Logistics ("we", "us", or
              "our") collects, uses, and shares information when you use our
              website or application (collectively, the "Service").
            </Typography>
            <Typography variant="h5" gutterBottom>
              Information We Collect
            </Typography>
            <Typography paragraph>
              We may collect certain personal information from you when you use
              the Service, such as name, email address
            </Typography>
            <Typography variant="h5" gutterBottom>
              How We Use Your Information
            </Typography>
            <Typography paragraph>
              We use the information we collect for various purposes, including
              but not limited to providing and improving the Service and sending
              notifications.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Information Sharing and Disclosure
            </Typography>
            <Typography paragraph>
              We may share your information with third parties if required by
              law.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Cookies and Similar Technologies
            </Typography>
            <Typography paragraph>
              We may use cookies and similar technologies to collect information
              and enhance your experience on the Service.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Your Choices
            </Typography>
            <Typography paragraph>
              You may have certain rights and choices regarding the information
              we collect about you. You have access to your data.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Security
            </Typography>
            <Typography paragraph>
              We take appropriate security measures to protect your information
              from unauthorized access or disclosure.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Updates to this Privacy Policy
            </Typography>
            <Typography paragraph>
              We may update this Privacy Policy from time to time, and any
              changes will be effective when posted on this page.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Contact Us
            </Typography>
            <Typography paragraph>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at{' '}
              <Link href="mailto:info@palnulogistics.com">
                info@palnulogistics.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
