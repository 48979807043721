import React from 'react'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import {useTheme} from '@mui/material/styles'
import {Link as RouterLink} from 'react-router-dom'
import {styled} from '@mui/system'

import GetStarted from './GetStarted'
import ScrollTop from './ScrollTop'

import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import manifestPowered from '../assets/manifest-powered.webp'
import logo from '../assets/logo-white.png'

const StyledLink = styled(Link)(({theme}) => ({
  textDecoration: 'none',
  color: theme.palette.common.white,
  '&:visited': {
    color: theme.palette.common.white,
  },
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export default function Footer(props) {
  const theme = useTheme()

  return (
    <Box
      component="footer"
      sx={{
        p: 5,
        color: theme.palette.common.white,
        background: theme.palette.secondary.main,
        fontSize: 12,
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={1}>
          <Grid item container justifyContent="center" sx={{mb: 3}}>
            <Box
              component="img"
              sx={{
                maxHeight: 60,
              }}
              alt="Palnu Logistics"
              src={logo}
            />
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={4}
            alignItems="center"
            justifyContent={{xs: 'center', md: 'flex-start'}}
            flexDirection={{xs: 'column', md: 'row'}}
            sx={{mb: 3}}
            rowSpacing={2}
            columnSpacing={2}
          >
            <Grid item>
              <StyledLink component={RouterLink} to="/terms">
                Terms Of Service
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink component={RouterLink} to="/privacy">
                Privacy Policy
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink component={RouterLink} to="/contact">
                Contact Us
              </StyledLink>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={4}
            alignItems="center"
            justifyContent="center"
            sx={{mb: 3}}
          >
            <Box component="a" href="https://twitter.com/" sx={{ml: 1}}>
              <TwitterIcon
                style={{fontSize: 30, color: theme.palette.common.white}}
              />
            </Box>
            <Box
              component="a"
              href="https://www.linkedin.com/in/palnu-info-aa5706278/"
              sx={{ml: 1}}
            >
              <LinkedInIcon
                style={{fontSize: 30, color: theme.palette.common.white}}
              />
            </Box>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={4}
            alignItems="center"
            justifyContent={{xs: 'center', md: 'flex-end'}}
            sx={{mb: 3}}
          >
            <Typography sx={{fontSize: 12, m: 0}}>
              &copy; {new Date().getUTCFullYear()} Palnu Logistics
            </Typography>
          </Grid>

          <Grid item container justifyContent="center">
            <Link
              href="https://www.liftedinit.org/"
              underline="none"
              sx={{display: 'block', my: 3, mx: 'auto', width: 200}}
            >
              <Box
                component="img"
                src={manifestPowered}
                style={{maxWidth: '100%'}}
                alt="Powered by The Manifest Network"
                sx={{boxShadow: '0 1px 9px rgba(0, 0, 0, 0.1)'}}
              />
            </Link>
          </Grid>
        </Grid>

        <ScrollTop {...props}>
          <Fab
            size="small"
            aria-label="scroll back to top"
            sx={{
              background: theme.palette.common.black,
              '&:hover': {background: theme.palette.secondary.main},
            }}
          >
            <KeyboardArrowUpIcon sx={{color: theme.palette.common.white}} />
          </Fab>
        </ScrollTop>
      </Container>

      <GetStarted
        modalOpen={props.modalOpen}
        handleCloseModal={props.handleCloseModal}
      />
    </Box>
  )
}
