import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import placeholderImg from '../assets/placeholder.png'
import art from '../assets/art3.png'

export default function Projects() {
  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          mt: 5,
          mb: 5,
          py: 10,
          background: '#eee',
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={{xs: 2, md: 5}}>
            <Grid item container xs={12} md={6}>
              <Typography variant="h2">Projects</Typography>
              <Typography component="p" variant="p">
                Pellentesque convallis accumsan suscipit aliquet eu diam quis
                nulla turpis. In mus massa lectus laoreet sed semper bibendum
                id. Iaculis purus malesuada porttitor aliquam pulvinar. Mi
                congue convallis tincidunt lobortis amet ultricies phasellus
                porttitor.
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              alignContent="center"
              justifyContent="center"
            >
              <Box
                component="img"
                src={art}
                alt="Palnu Logistics"
                sx={{maxHeight: 300}}
              />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container maxWidth="md" sx={{my: 10}}>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={6} alignContent="center">
            <Box
              component="img"
              src={placeholderImg}
              alt="Palnu Logistics"
              sx={{maxHeight: 300}}
            />
          </Grid>
          <Grid item container xs={12} md={6}>
            {[1, 2, 3, 4].map((index) => {
              return (
                <Grid item container xs={6} key={`card-${index}`}>
                  <Box
                    component="img"
                    src={placeholderImg}
                    alt="Palnu Logistics"
                    sx={{maxHeight: 30, mb: 2}}
                  />
                  <Typography component="p" variant="p">
                    Egestas elit dui scelerisque ut eu purus aliquam vitae
                    habitasse.
                  </Typography>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md" sx={{my: 10}}>
        <Grid
          container
          spacing={3}
          direction={{xs: 'column-reverse', md: 'row'}}
        >
          <Grid item container xs={12} md={6} alignContent="center">
            <Typography variant="h2">
              Pellentesque convallis accumsan suscipi
            </Typography>
            <Typography component="p" variant="p">
              Mi congue convallis tincidunt lobortis amet ultricies phasellus
              porttitor.
            </Typography>
          </Grid>
          <Grid container item xs={12} md={6} alignContent="center">
            <Box
              component="img"
              src={placeholderImg}
              alt="Palnu Logistics"
              sx={{maxHeight: 300}}
            />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md" sx={{my: 10}}>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={6} alignContent="center">
            <Box
              component="img"
              src={placeholderImg}
              alt="Palnu Logistics"
              sx={{maxHeight: 300}}
            />
          </Grid>
          <Grid item container xs={12} md={6} alignContent="center">
            <Typography variant="h2">
              Pellentesque convallis accumsan suscipi
            </Typography>
            <Typography component="p" variant="p">
              Mi congue convallis tincidunt lobortis amet ultricies phasellus
              porttitor.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
