import React, {Fragment, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import classnames from 'classnames'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Drawer from '@mui/material/Drawer'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import {useTheme} from '@mui/material/styles'

import MenuIcon from '@mui/icons-material/Menu'

import logo from '../assets/logo.png'

const nav = [
  {
    label: 'How It Works',
    route: '/how-it-works',
  },
  // {
  //   label: 'Projects',
  //   route: '/projects',
  // },
  {
    label: 'Technology',
    route: '/technology',
  },
  {
    label: 'About Us',
    route: '/about',
  },
  // {
  //   label: 'Blog',
  //   route: '/blog',
  // },
]

const NavItem = ({anchor, children, label, href, route}, index) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const {pathname} = location
  const [open, setOpen] = useState(false)
  const [id] = useState(`menu-item-${label.toLowerCase().replace(/ /g, '')}`)

  return (
    <Box
      onMouseOver={() => {
        setOpen(true)
      }}
      onMouseOut={() => {
        setOpen(false)
      }}
    >
      <Button
        id={id}
        variant="nav"
        className={classnames({
          active: route === pathname,
          open,
        })}
        sx={{
          position: 'relative',
          display: 'block',
          '&.active': {color: theme.palette.common.black},
          '&.open': {background: 'rgba(0, 0, 0, 0.04)'},
        }}
        href={href}
        onClick={() => {
          if (children) return
          setOpen(false)
          if (route) navigate(route)
          if (anchor) navigate(`/${anchor}`)
        }}
      >
        {label}
      </Button>

      {children ? (
        <Box
          sx={{
            visibility: open ? 'visible' : 'hidden',
            opacity: open ? 1 : 0,
            transition: 'opacity 300ms',
            position: 'absolute',
            top: 58,
            zIndex: 5,
            minWidth: 200,
            background: '#fff',
            boxShadow: '0 5px 10px rgb(0 0 0 / 10%)',
            borderRadius: 2,
          }}
        >
          {children.map((child, childIndex) => {
            return (
              <MenuItem
                key={`menu-item-${index}-${childIndex}`}
                sx={{
                  '&:first-of-type': {
                    borderTopRightRadius: 6,
                    borderTopLeftRadius: 6,
                  },
                  p: 0,
                }}
              >
                <ListItemButton
                  href={child.href}
                  onClick={() => {
                    setOpen(false)
                    if (child.route) navigate(child.route)
                    if (child.anchor) navigate(`/${child.anchor}`)
                  }}
                  sx={{
                    '&:hover': {
                      background: 'none',
                    },
                  }}
                >
                  {child.label}
                </ListItemButton>
              </MenuItem>
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}

export default function Header() {
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  return (
    <>
      <div id="back-to-top-anchor" />
      <Box
        // sx={{position: 'sticky', top: 0, zIndex: 4}}
        className="page page--landing"
      >
        <AppBar
          position="static"
          sx={{background: '#fff', boxShadow: 'none', color: '#333', pb: 0.9}}
        >
          <Container maxWidth="md">
            <Toolbar disableGutters>
              <Grid
                container
                spacing={1}
                sx={{
                  my: 0.5,
                  display: {xs: 'flex', md: 'none'},
                }}
              >
                <Grid item container xs={10} alignContent="center">
                  <Box
                    component="img"
                    sx={{
                      maxHeight: 30,
                      cursor: 'pointer',
                    }}
                    alt="Palnu Logistics"
                    src={logo}
                    onClick={() => {
                      navigate('/')
                      window.scrollTo(0, 0)
                    }}
                  />
                </Grid>
                <Grid item container xs={2} justifyContent="flex-end">
                  <IconButton
                    size="large"
                    onClick={() => setDrawerOpen(true)}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                sx={{
                  my: 0.5,
                  display: {xs: 'none', md: 'flex'},
                }}
              >
                <Grid item container xs={3} alignContent="center">
                  <Box
                    component="img"
                    sx={{
                      maxHeight: 30,
                      cursor: 'pointer',
                    }}
                    alt="Palnu Logistics"
                    src={logo}
                    onClick={() => {
                      navigate('/')
                      window.scrollTo(0, 0)
                    }}
                  />
                </Grid>
                <Grid item container xs={7} alignContent="center">
                  {nav.map((item, index) => {
                    return <NavItem key={`menu-item-${index}`} {...item} />
                  })}
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  alignContent="center"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="primary"
                    onClick={() => navigate('/contact')}
                    sx={{
                      display: {xs: 'none', md: 'block'},
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Contact Us
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List sx={{width: 250}}>
          {nav.map(({anchor, children, label, href, route}, index) => {
            return (
              <Fragment key={`mobile-menu-item-${index}`}>
                <ListItem disablePadding sx={{borderBottom: '1px solid #ddd'}}>
                  <ListItemButton
                    href={href}
                    onClick={() => {
                      if (children) return
                      setDrawerOpen(false)
                      if (route) navigate(route)
                      if (anchor) navigate(`/${anchor}`)
                    }}
                    sx={{
                      '&:hover': {
                        background: children ? '#fff' : '#f1f1f1',
                        cursor: children ? 'default' : 'pointer',
                      },
                    }}
                  >
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
                {children ? (
                  <Box sx={{background: '#fff'}}>
                    {children.map((child, childIndex) => {
                      return (
                        <ListItem
                          disablePadding
                          sx={{borderBottom: '1px solid #ddd', pl: 2}}
                          key={`mobile-menu-item-${index}-${childIndex}`}
                        >
                          <ListItemButton
                            href={child.href}
                            onClick={() => {
                              setDrawerOpen(false)
                              if (child.route) navigate(child.route)
                              if (child.anchor) navigate(`/${child.anchor}`)
                            }}
                          >
                            <ListItemText primary={child.label} />
                          </ListItemButton>
                        </ListItem>
                      )
                    })}
                  </Box>
                ) : null}
              </Fragment>
            )
          })}
          <ListItem
            disablePadding
            sx={{borderBottom: '1px solid #ddd'}}
            key={`mobile-menu-item-last`}
          >
            <ListItemButton
              href=""
              onClick={() => {
                setDrawerOpen(false)
                navigate('/contact')
              }}
            >
              <ListItemText primary="Contact Us" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}
