import {useEffect, useState} from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import MailIcon from '@mui/icons-material/Mail'
import emailjs from '@emailjs/browser'

export default function Contact() {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)
    emailjs.init('3Aukb22LRkm8NTuUU')
    emailjs.send('service_8xdlg7q', 'template_dw0dhde', formState).then(
      (response) => {
        setIsSubmitting(false)
        setIsComplete(true)
      },
      (error) => {
        setIsSubmitting(false)
        setIsComplete(true)
      }
    )
  }

  useEffect(() => {
    const emailValid = formState.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    )
    const firstValid = formState.firstName.length > 1
    const lastValid = formState.lastName.length > 1

    setIsValid(emailValid && firstValid && lastValid)
  }, [formState])

  return (
    <>
      <Container maxWidth="md" sx={{mt: 10, mb: 15}}>
        <Grid container item xs={12} sm={8} md={6}>
          <Grid item container xs={12}>
            <Typography variant="h2">Contact Us</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Typography component="p" variant="p">
              Please feel free to reach out using the form below, and our
              dedicated team will get back to you as soon as possible.
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            {!isComplete ? (
              <FormGroup>
                <Grid container spacing={2}>
                  <Grid container item xs={12} spacing={5}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="First Name"
                          value={formState.firstName}
                          onChange={(ev) => {
                            setFormState({
                              ...formState,
                              firstName: ev.target.value,
                            })
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Last Name"
                          value={formState.lastName}
                          onChange={(ev) => {
                            setFormState({
                              ...formState,
                              lastName: ev.target.value,
                            })
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Email"
                          value={formState.email}
                          onChange={(ev) => {
                            setFormState({...formState, email: ev.target.value})
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      color="primary"
                      onClick={handleSubmit}
                      loading={isSubmitting}
                      disabled={!isValid}
                      fullWidth
                      loadingPosition="start"
                      startIcon={<MailIcon />}
                      variant="primary"
                      sx={{
                        mt: 3,
                        p: 1.4,
                        '&.MuiLoadingButton-loading': {color: '#fff'},
                      }}
                    >
                      <span>{isSubmitting ? '' : 'Submit'}</span>
                    </LoadingButton>
                  </Grid>
                </Grid>
              </FormGroup>
            ) : (
              <Grid container item xs={12} sx={{my: 6}}>
                <Grid item container xs={12}>
                  <Typography variant="h2">Thanks!</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Typography component="p" variant="p">
                    Thank you for reaching out to us! We look forward to
                    connecting with you soon.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
