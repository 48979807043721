import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import placeholderImg from '../assets/placeholder3.png'

export default function Blog() {
  return (
    <>
      <Container maxWidth="md" sx={{my: 10}}>
        <Grid
          container
          spacing={3}
          direction={{xs: 'column-reverse', md: 'row'}}
        >
          <Grid item container xs={12} md={6} alignContent="center">
            <Typography variant="h2">
              Pellentesque convallis accumsan suscipi
            </Typography>
            <Typography component="p" variant="p">
              Mi congue convallis tincidunt lobortis amet ultricies phasellus
              porttitor.
            </Typography>
          </Grid>
          <Grid container item xs={12} md={6} alignContent="center">
            <Box
              component="img"
              src={placeholderImg}
              alt="Palnu Logistics"
              sx={{maxHeight: 300}}
            />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md" sx={{my: 10}}>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={6} alignContent="center">
            <Box
              component="img"
              src={placeholderImg}
              alt="Palnu Logistics"
              sx={{maxHeight: 300}}
            />
          </Grid>
          <Grid item container xs={12} md={6} alignContent="center">
            <Typography variant="h2">
              Pellentesque convallis accumsan suscipi
            </Typography>
            <Typography component="p" variant="p">
              Mi congue convallis tincidunt lobortis amet ultricies phasellus
              porttitor.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md" sx={{my: 10}}>
        <Grid
          container
          spacing={3}
          direction={{xs: 'column-reverse', md: 'row'}}
        >
          <Grid item container xs={12} md={6} alignContent="center">
            <Typography variant="h2">
              Pellentesque convallis accumsan suscipi
            </Typography>
            <Typography component="p" variant="p">
              Mi congue convallis tincidunt lobortis amet ultricies phasellus
              porttitor.
            </Typography>
          </Grid>
          <Grid container item xs={12} md={6} alignContent="center">
            <Box
              component="img"
              src={placeholderImg}
              alt="Palnu Logistics"
              sx={{maxHeight: 300}}
            />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md" sx={{my: 10}}>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={6} alignContent="center">
            <Box
              component="img"
              src={placeholderImg}
              alt="Palnu Logistics"
              sx={{maxHeight: 300}}
            />
          </Grid>
          <Grid item container xs={12} md={6} alignContent="center">
            <Typography variant="h2">
              Pellentesque convallis accumsan suscipi
            </Typography>
            <Typography component="p" variant="p">
              Mi congue convallis tincidunt lobortis amet ultricies phasellus
              porttitor.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
