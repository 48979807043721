import {useState} from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {Link as RouterLink} from 'react-router-dom'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import {useTheme} from '@mui/material/styles'

import CloseIcon from '@mui/icons-material/Close'

import art from '../assets/art2.png'
import mapImg from '../assets/map.png'
import pinImg from '../assets/pin.png'

const locations = [
  {
    city: 'Freemont',
    state: 'CA',
    coords: {x: 60, y: 160},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Cincinnati',
    state: 'OH',
    coords: {x: 630, y: 170},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Fort Lauderdale',
    state: 'FL',
    coords: {x: 720, y: 380},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Tampa West',
    state: 'FL',
    coords: {x: 680, y: 370},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Tampa North',
    state: 'FL',
    coords: {x: 660, y: 350},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Charlotte',
    state: 'NC',
    coords: {x: 680, y: 220},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Louisville',
    state: 'KY',
    coords: {x: 600, y: 180},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Raleigh',
    state: 'NC',
    coords: {x: 720, y: 210},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Denver',
    state: 'CO',
    coords: {x: 310, y: 170},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Nashville',
    state: 'TN',
    coords: {x: 590, y: 220},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Richmond',
    state: 'VA',
    coords: {x: 710, y: 170},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Minneapolis',
    state: 'MN',
    coords: {x: 480, y: 60},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
  {
    city: 'Philadelphia',
    state: 'PA',
    coords: {x: 746, y: 112},
    description: 'Pellentesque auctor adipiscing lacus viverra.',
  },
]

export default function Services(props) {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState(null)
  // const [pinDescription, setPinDescription] = useState(null)
  const [pinTitle, setPinTitle] = useState(null)

  const handleClick = ({ev, title, description}) => {
    setPinTitle(title)
    // setPinDescription(description)
    setAnchorEl(ev.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          mt: 0,
          mb: 5,
          py: 10,
          background: '#eee',
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={{xs: 2, md: 5}}>
            <Grid item container xs={12} md={6}>
              <Typography variant="h2">How It Works</Typography>
              <Typography component="p" variant="p">
                At Palnu, we pride ourselves on being more than just a service
                provider. We offer a comprehensive turnkey solution, ensuring a
                seamless journey from project inception to successful delivery.
                With us as your trusted partner, rest assured that every step of
                your datacenter deployment is in expert hands.
              </Typography>
              {/* <Button
                variant="primary"
                onClick={props.handleOpenModal}
                sx={{
                  width: {xs: '100%', sm: 'auto'},
                  whiteSpace: 'nowrap',
                }}
              >
                Let's get Started
              </Button> */}
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              alignContent="center"
              justifyContent="center"
            >
              <Box
                component="img"
                src={art}
                alt="Palnu Logistics"
                sx={{maxHeight: 300}}
              />
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container maxWidth="md" sx={{py: 8}}>
        <Grid container spacing={2}>
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="h2" sx={{mb: 5}}>
              Our Turnkey Advantage
            </Typography>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent="center">
            <Typography variant="h4" align="center">
              Purpose-Built Tooling Installation
            </Typography>
            <Typography component="p" variant="p" align="center">
              Palnu installs cutting-edge, purpose-built tooling in datacenters,
              empowering clients to focus on their core competencies while the
              company handles the technical infrastructure
            </Typography>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent="center">
            <Typography variant="h4" align="center">
              Datacenter Deployment Services
            </Typography>
            <Typography component="p" variant="p" align="center">
              Palnu offers comprehensive datacenter deployment services,
              ensuring successful and efficient setup of large IT infrastructure
              workloads
            </Typography>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent="center">
            <Typography variant="h4" align="center">
              Decentralized Digital Future Solutions
            </Typography>
            <Typography component="p" variant="p" align="center">
              Palnu equips businesses and organizations with the tools and
              expertise to thrive in the decentralized digital landscape,
              positioning clients for success in the evolving market
            </Typography>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent="center">
            <Typography variant="h4" align="center">
              Security and Reliability Assurance
            </Typography>
            <Typography component="p" variant="p" align="center">
              Palnu prioritizes datacenter security and reliability,
              implementing stringent measures and quality checks throughout the
              process to ensure the utmost protection
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Button variant="primary" component={RouterLink} to="/contact">
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Container
        disableGutters
        maxWidth={false}
        sx={{
          py: 8,
          background: theme.palette.secondary.lighter,
          display: {xs: 'flex', md: 'none'},
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="h2" align="center" sx={{mb: 3}}>
                Infrastructure
              </Typography>
            </Grid>
            {locations.map(({city, state, description}) => {
              return (
                <Grid item xs={12} sx={{mb: 5}} key={`loc-${city}-${state}`}>
                  <Typography variant="h4" ssx={{mb: 2}}>
                    {city} {state}
                  </Typography>
                  {/* <Typography component="p" variant="p">
                    {description}
                  </Typography>
                  <Button variant="primary">Details</Button> */}
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </Container>

      <Container
        maxWidth="md"
        sx={{
          mb: 15,
          display: {xs: 'none', md: 'flex'},
        }}
      >
        <Grid container spacing={2}>
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="h2" align="center" sx={{mb: 3}}>
              Infrastructure
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <Box sx={{position: 'relative'}}>
              {locations.map(({city, state, description, coords: {x, y}}) => {
                return (
                  <Box
                    key={`pin-${city}-${state}`}
                    component="img"
                    src={pinImg}
                    alt="pin"
                    sx={{
                      cursor: 'pointer',
                      width: 30,
                      position: 'absolute',
                      left: x,
                      top: y,
                      zIndex: 1,
                      transition: 'all 200ms linear',
                      '&:hover': {
                        transform: 'scale(1.2)',
                        filter: 'drop-shadow(0 -2px 1px rgba(0, 0, 0, 0.2))',
                      },
                    }}
                    onClick={(ev) =>
                      handleClick({
                        ev,
                        title: `${city} ${state}`,
                        description,
                      })
                    }
                  />
                )
              })}

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box sx={{p: 2}}>
                  <Grid container>
                    <Grid item xs={9} alignItems="center">
                      <Typography variant="h4" sx={{whiteSpace: 'nowrap'}}>
                        {pinTitle}
                      </Typography>
                    </Grid>
                    <Grid container item xs={3} alignItems="flex-start">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={handleClose}
                        sx={{ml: 'auto'}}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>

                  {/* <Typography component="p" variant="p">
                    {pinDescription}
                  </Typography> */}
                </Box>
              </Popover>
              <Box component="img" src={mapImg} alt="map" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
