import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import art from '../assets/art3.png'

export default function About() {
  return (
    <>
      <Container maxWidth="md" sx={{mt: 10, mb: 25}}>
        <Grid
          container
          spacing={3}
          direction={{xs: 'column-reverse', md: 'row'}}
        >
          <Grid item container xs={12} md={6} alignContent="center">
            <Typography variant="h2">About Us</Typography>
            <Typography component="p" variant="p">
              Palnu is a turnkey solution for those at the forefront of the
              decentralized digital future, where the deployment of large IT
              infrastructure workload is critical. We handle projects from
              inception to delivery, and provide white-glove service for
              hands-off customers that trust us to get the job done right.
            </Typography>
            <Typography component="p" variant="p">
              Our team works with datacenter operators across the world,
              installing tooling that is purpose built to help our customers
              focus on what they do best. Our expertise extends deeply into the
              human side of data management— we use empathy and customer service
              as the platform through which we improve infrastructure design and
              identify needs quickly to create a tailor-made logistics roadmap.
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            alignContent="top"
            justifyContent="center"
          >
            <Box
              component="img"
              src={art}
              alt="Palnu Logistics"
              sx={{maxHeight: 300, mt: {xs: 0, md: 3}}}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
