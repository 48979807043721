import {useState} from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import {ThemeProvider} from '@mui/material/styles'
import {Routes, Route} from 'react-router-dom'
import theme from './theme'
import 'animate.css'

import Footer from './components/Footer'
import Header from './components/Header'
import ScrollHelper from './components/ScrollHelper'

import About from './pages/About'
import Blog from './pages/Blog'
import Contact from './pages/Contact'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import PrivacyPolicy from './pages/PrivacyPolicy'
import HowItWorks from './pages/HowItWorks'
import Projects from './pages/Projects'
import Technology from './pages/Technology'
import TermsOfService from './pages/TermsOfService'

function App() {
  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  return (
    <ThemeProvider theme={theme}>
      <ScrollHelper />
      <CssBaseline />
      <Header />

      <Routes>
        <Route path="/" element={<Home handleOpenModal={handleOpenModal} />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route
          path="/how-it-works"
          element={<HowItWorks handleOpenModal={handleOpenModal} />}
        />
        <Route path="/projects" element={<Projects />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/terms" element={<TermsOfService />} />

        <Route path="/*" element={<NotFound />} />
      </Routes>

      <Footer modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
    </ThemeProvider>
  )
}

export default App
