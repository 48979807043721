import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {useTheme} from '@mui/material/styles'

import art from '../assets/art4.png'

export default function Technology() {
  const theme = useTheme()

  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          mt: 0,
          mb: 5,
          py: 7,
          background: theme.palette.secondary.lighter,
        }}
      >
        <Container maxWidth="md" sx={{my: 5}}>
          <Grid container rowSpacing={1}>
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="h2" align="center" sx={{mb: 4}}>
                Empowering the Decentralized Future
              </Typography>
              <Typography component="p" variant="p" align="center">
                Embrace the decentralized digital frontier with confidence.
                Palnu is committed to empowering businesses and organizations
                that dare to forge ahead in this rapidly evolving landscape. We
                equip you with the right tools and logistics to conquer new
                horizons and thrive in the decentralized realm.
              </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Box
                component="img"
                src={art}
                alt="Palnu Logistics"
                sx={{
                  maxHeight: 300,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container maxWidth="md" sx={{mt: 5, mb: 15}}>
        <Grid container spacing={4}>
          <Grid item container xs={12}>
            <Typography variant="h2" align="center" sx={{mb: 4}}>
              Your Gateway to Seamless Tooling and Logistics Solutions
            </Typography>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent="center">
            <Typography variant="h4" align="center">
              Automation and Orchestration
            </Typography>
            <Typography component="p" variant="p" align="center">
              Automation plays a vital role in Palnu's services. The company
              implements automation and orchestration tools to streamline
              routine tasks, improve response times, and reduce the risk of
              human errors. This results in enhanced operational efficiency and
              resource utilization
            </Typography>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent="center">
            <Typography variant="h4" align="center">
              Logistics and Supply Chain Management
            </Typography>
            <Typography component="p" variant="p" align="center">
              Palnu's logistics operations are powered by modern supply chain
              management tools. These technologies enable efficient inventory
              management, order processing, and tracking, ensuring timely
              delivery of critical equipment and reducing downtime risks
            </Typography>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent="center">
            <Typography variant="h4" align="center">
              Decentralization Technologies
            </Typography>
            <Typography component="p" variant="p" align="center">
              Palnu stays at the forefront of decentralized technologies. This
              includes expertise in blockchain, distributed ledger systems, and
              decentralized applications (dApps). The company helps clients
              navigate these technologies to harness their benefits effectively
            </Typography>
          </Grid>
          <Grid item container xs={12} md={6} justifyContent="center">
            <Typography variant="h4" align="center">
              Data Management and Data Governance
            </Typography>
            <Typography component="p" variant="p" align="center">
              In line with its empathetic approach to data management, Palnu
              employs tools and processes to ensure data governance, data
              lifecycle management, and compliance with data regulations
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
