import {createTheme} from '@mui/material/styles'

const colors = {
  primary: '#ea3323',
  primaryLighter: '#ece8e7',
  secondary: '#000000',
  secondaryLighter: '#eeeeee',
  white: '#ffffff',
  black: '#000000',
  visited: '#000000',
  grey0: '#cccccc',
  grey1: '#dddddd',
  grey2: '#eeeeee',
}

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.primary,
      lighter: colors.primaryLighter,
    },
    secondary: {
      main: colors.secondary,
      lighter: colors.secondaryLighter,
    },
  },
  typography: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    p: {
      margin: '0 0 1.25rem',
    },
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
      margin: '0 0 1.25rem',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 700,
      margin: '0 0 1.25rem',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 700,
      margin: '0 0 1.25rem',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 700,
      margin: '0 0 .5rem',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: ({theme}) => ({
          color: colors.primary,
          '&:visited': {
            color: colors.secondary,
            textDecorationColor: colors.secondary,
          },
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          padding: '5px 15px',
          borderRadius: 4,
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
      variants: [
        {
          props: {variant: 'primary'},
          style: {
            background: colors.primary,
            border: `1px solid ${colors.primary}`,
            color: colors.white,
            '&:hover': {
              background: colors.primary,
            },
          },
        },
        {
          props: {variant: 'contained'},
          style: {
            background: colors.white,
            border: `1px solid ${colors.primary}`,
            color: colors.primary,
            '&:hover': {
              background: colors.white,
            },
          },
        },
        {
          props: {variant: 'nav'},
          style: {
            background: colors.white,
            textTransform: 'none',
            border: `1px solid ${colors.white}`,
            '&:hover': {
              background: colors.grey2,
              border: `1px solid ${colors.grey2}`,
            },
          },
        },
      ],
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 20,
        },
      },
    },
  },
})

export default theme
