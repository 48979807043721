import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
// import Link from '@mui/material/Link'
// import {Link as RouterLink} from 'react-router-dom'
// import Button from '@mui/material/Button'

import {useTheme} from '@mui/material/styles'

import Slideshow from '../components/Slideshow'

import partner1 from '../assets/partner1.png'
import partner2 from '../assets/partner2.png'
import partner3 from '../assets/partner3.png'
import art1 from '../assets/art1.png'

export default function Home(props) {
  const theme = useTheme()

  return (
    <>
      <Container maxWidth="md" sx={{mt: 8, mb: 8}}>
        <Grid
          container
          spacing={{xs: 2, md: 5}}
          direction={{xs: 'column-reverse', md: 'row'}}
        >
          <Grid item container xs={12} md={6}>
            <Typography variant="h1">
              Hassle free web-3 node operations
            </Typography>
            <Typography component="p" variant="p">
              We are entering a new era of large IT infrastructure workloads,
              where rapid deployment and lean team management are necessary to
              scale effectively. At Palnu, we mirror the Web 3.0 mentality in
              developing and decentralizing your infrastructure to minimize
              downtime and optimize performance.
            </Typography>

            {/* <Grid item container spacing={3} sx={{mt: 1}}>
              <Grid
                item
                xs={6}
                sx={{
                  flexBasis: {sm: 'min-content'},
                }}
              >
                <Button
                  variant="primary"
                  onClick={props.handleOpenModal}
                  sx={{
                    width: {xs: '100%', sm: 'auto'},
                    whiteSpace: 'nowrap',
                  }}
                >
                  Let's Get Started
                </Button>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            alignContent="center"
            justifyContent="center"
          >
            <Box
              component="img"
              src={art1}
              alt="Palnu Logistics"
              sx={{maxHeight: 300}}
            />
          </Grid>
        </Grid>
      </Container>

      <Container disableGutters maxWidth={false}>
        <Box
          component="section"
          sx={{
            py: 8,
            background: theme.palette.secondary.lighter,
          }}
        >
          <Container maxWidth="md">
            <Grid container spacing={5}>
              <Grid item container xs={12} justifyContent="center">
                <Typography variant="h2">Our Partners</Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={4}
                alignContent="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  src={partner1}
                  alt="Database Labs"
                  sx={{height: 20}}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={4}
                alignContent="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  src={partner2}
                  alt="The Lifted Initiative"
                  sx={{height: 15}}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={4}
                alignContent="center"
                justifyContent="center"
              >
                <Box
                  component="img"
                  src={partner3}
                  alt="Hive Mind"
                  sx={{height: 20}}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>

      <Container maxWidth="md" sx={{py: 8}}>
        <Grid container spacing={2}>
          <Grid item container xs={12} justifyContent="center">
            <Typography variant="h2">What We Do</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Typography component="p" variant="p">
              Palnu is a Turnkey solution that makes your life easy through
              hassle free web-3 node operations. We make your life easy to turn
              investment into tokens through programs.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container disableGutters maxWidth={false}>
        <Box
          component="section"
          sx={{
            py: 8,
            background: theme.palette.secondary.lighter,
          }}
        >
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item container xs={12} justifyContent="center">
                <Typography variant="h2">Client Testimonials</Typography>
              </Grid>
              <Grid item container xs={12} justifyContent="center">
                <Slideshow>
                  <Grid container spacing={2}>
                    <Grid item container xs={12} justifyContent="center">
                      <Typography component="p" variant="p">
                        "Palnu's turnkey solution made our decentralized digital
                        journey stress-free. Their purpose-built tooling and
                        efficient logistics optimized our datacenter's
                        performance, exceeding our expectations."
                      </Typography>
                    </Grid>
                    {/* <Grid item container xs={12} justifyContent="center">
                      <Typography>
                        <strong>Author Name</strong>
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                      <Typography>CEO Zoomerr</Typography>
                    </Grid> */}
                  </Grid>
                  <>
                    <Grid container spacing={2}>
                      <Grid item container xs={12} justifyContent="center">
                        <Typography component="p" variant="p">
                          "Palnu's customer-centric approach and cutting-edge
                          technology transformed our datacenter operations.
                          Their tailor-made logistics roadmap perfectly catered
                          to our needs."
                        </Typography>
                      </Grid>
                      {/* <Grid item container xs={12} justifyContent="center">
                        <Typography>
                          <strong>Author Name</strong>
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} justifyContent="center">
                        <Typography>CEO Author</Typography>
                      </Grid> */}
                    </Grid>
                  </>
                  <>
                    <Grid container spacing={2}>
                      <Grid item container xs={12} justifyContent="center">
                        <Typography component="p" variant="p">
                          "Palnu's advanced tooling and proactive monitoring
                          improved our efficiency. Their global logistics
                          expertise expanded our reach while maintaining
                          security. A true growth partner!"
                        </Typography>
                      </Grid>
                      {/* <Grid item container xs={12} justifyContent="center">
                        <Typography>
                          <strong>Author Name</strong>
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} justifyContent="center">
                        <Typography>COO Test</Typography>
                      </Grid> */}
                    </Grid>
                  </>
                </Slideshow>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
    </>
  )
}
