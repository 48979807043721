import React, {useEffect, useState} from 'react'
import {Box} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded'

const Slideshow = ({children}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [prevDisabled, setPrevDisabled] = useState(false)
  const [nextDisabled, setNextDisabled] = useState(false)

  useEffect(() => {
    setPrevDisabled(activeIndex === 0)
    setNextDisabled(activeIndex === children.length - 1)
  }, [activeIndex, children])

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % children.length)
  }

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? children.length - 1 : prevIndex - 1
    )
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{width: '100%'}}
    >
      <IconButton
        size="large"
        onClick={handlePrev}
        disabled={prevDisabled}
        sx={{mt: -14}}
      >
        <NavigateBeforeRoundedIcon />
      </IconButton>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          position: 'relative',
          height: 320,
          width: {xs: '90%', sm: '50%'},
        }}
      >
        {children.map((child, index) => (
          <Box
            key={index}
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              transition: 'transform 0.5s',
              transform: `translateX(${(index - activeIndex) * 100}%)`,
            }}
          >
            <Paper sx={{mx: 2, p: 4}}>{child}</Paper>
          </Box>
        ))}
      </Box>
      <IconButton
        size="large"
        onClick={handleNext}
        disabled={nextDisabled}
        sx={{mt: -14}}
      >
        <NavigateNextRoundedIcon />
      </IconButton>
    </Box>
  )
}

export default Slideshow
