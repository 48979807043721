import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

const scrollTo = (anchor, yOffset = 0) => {
  const el = document.querySelector(`#${anchor}`)
  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset
  window.scrollTo({top: y, behavior: 'instant'})
}

export default function ScrollHelper() {
  const location = useLocation()
  const {pathname} = location

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    })
  }, [pathname])

  useEffect(() => {
    const anchor = window.location.hash.slice(1)
    if (anchor) {
      const anchorEl = document.getElementById(anchor)
      if (anchorEl) {
        scrollTo(anchor, -50)
      }
    }
  }, [location])

  return null
}
