import React from 'react'
import Modal from '@mui/material/Modal'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
// import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
// import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'

const steps = [
  {
    label: 'Pick A Program',
    body: (
      <>
        <List component="nav" aria-label="secondary mailbox folder">
          <ListItemButton selected={false} onClick={() => {}}>
            <ListItemText primary="Option1" />
          </ListItemButton>
          <ListItemButton selected={false} onClick={() => {}}>
            <ListItemText primary="Option2" />
          </ListItemButton>
        </List>
      </>
    ),
  },
  {
    label: 'Enter Credit Card',
    body: (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            m: 1,
            height: 300,
            width: '90%',
            outline: '1px dashed #bbb ',
          }}
        >
          Payment Gateway Here
        </Box>
      </>
    ),
  },
  {
    label: 'Start Earning',
    body: (
      <>
        <Typography component="p" variant="p">
          Congratulations!
        </Typography>
      </>
    ),
  },
]

export default function GeteStarted(props) {
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleFinish = () => {
    setActiveStep(0)
    props.handleCloseModal()
  }

  return (
    <Modal
      open={props.modalOpen}
      onClose={props.handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '5vh',
          left: '50%',
          height: '90vh',
          transform: 'translate(-50%, 0)',
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          'overflow-y': 'scroll',
          p: {xs: 1, sm: 4},
          maxWidth: 640,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography variant="h3">Let's Get Started</Typography>
            <Typography component="p" variant="p">
              Follow this 3-step process to get started.
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={2}
            justifyContent="flex-end"
            alignContent="start"
          >
            <IconButton aria-label="close" onClick={props.handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => {
            const isLast = index === steps.length - 1
            return (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  <Typography>{step.body}</Typography>
                  <Box sx={{mb: 2}}>
                    {index !== 0 && (
                      <Button onClick={handleBack} sx={{mt: 1, mr: 1}}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      onClick={isLast ? handleFinish : handleNext}
                      sx={{mt: 1, mr: 1}}
                    >
                      {index === steps.length - 1 ? 'Finish' : 'Continue'}
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            )
          })}
        </Stepper>
      </Box>
    </Modal>
  )
}
