import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export default function TermsOfService() {
  return (
    <>
      <Container maxWidth="md" sx={{mt: 5, mb: 15}}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Terms of Service
            </Typography>
            <Typography paragraph>
              Welcome to Palnu Logistics ("we", "us", or "our"). By accessing or
              using our website or application (the "Service"), you agree to be
              bound by these Terms of Service ("Terms"). If you do not agree to
              these Terms, please do not use the Service.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Use of the Service
            </Typography>
            <Typography paragraph>
              The Service is provided solely for informational and personal use
              purposes. You may not use the Service for any illegal,
              unauthorized, or prohibited activities.
            </Typography>
            <Typography variant="h5" gutterBottom>
              User Accounts
            </Typography>
            <Typography paragraph>
              To access certain features or areas of the Service, you may need
              to create a user account. You are responsible for maintaining the
              confidentiality of your account credentials and are fully
              responsible for all activities that occur under your account.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Intellectual Property
            </Typography>
            <Typography paragraph>
              The Service and its content, including but not limited to text,
              graphics, images, logos, trademarks, and software, are the
              property of Palnu Logistics and its licensors and are protected by
              copyright, trademark, and other intellectual property laws. You
              may not use, reproduce, modify, distribute, or display any portion
              of the Service without our prior written consent.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Disclaimer of Warranties
            </Typography>
            <Typography paragraph>
              The Service is provided "as is" and "as available" without
              warranties of any kind, whether express or implied. We do not
              warrant that the Service will be uninterrupted, error-free, or
              secure. Your use of the Service is at your own risk.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Limitation of Liability
            </Typography>
            <Typography paragraph>
              In no event shall Palnu Logistics be liable for any indirect,
              incidental, special, consequential, or punitive damages arising
              out of or in connection with your use of the Service or these
              Terms.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Governing Law
            </Typography>
            <Typography paragraph>
              These Terms shall be governed by and construed in accordance with
              the law.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Changes to these Terms
            </Typography>
            <Typography paragraph>
              We reserve the right to update or modify these Terms at any time
              without prior notice. Your continued use of the Service after such
              changes will constitute your acceptance of the revised Terms.
            </Typography>
            <Typography variant="h5" gutterBottom>
              Contact Us
            </Typography>
            <Typography paragraph>
              If you have any questions or concerns about these Terms, please
              contact us at{' '}
              <Link href="mailto:info@palnulogistics.com">
                info@palnulogistics.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
